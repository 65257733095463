import React from 'react'


const error = () => {
    return (
        <div>
            this is an error page
        </div>
    )
}

export default error
